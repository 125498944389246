import { useCallback } from 'react';
import { useResizeObserver } from '../../../../../providers/useResizeObserver/useResizeObserver';

export const useComponentHeight = (
  componentRef: React.RefObject<HTMLDivElement>,
) => {
  const updateComponentHeight = useCallback(() => {
    const componentHeight = componentRef?.current?.clientHeight;
    componentRef?.current?.style.setProperty(
      '--component-height',
      `${componentHeight}px`,
    );
  }, [componentRef]);

  useResizeObserver({
    callback: updateComponentHeight,
    ref: componentRef,
  });
};
