import React, { useMemo, useCallback, useState } from 'react';

import { GestureEventHandler } from '../../../../../../providers/useGesture/useGesture';
import { ISearchInputProps } from './SearchInput';

type IUseAutocompleteParams = Pick<
  ISearchInputProps,
  'autocompleteValue' | 'id' | 'onAutocomplete' | 'value'
> & {
  onChange(value: string): void;
};

export const useAutocomplete = ({
  autocompleteValue,
  id,
  onAutocomplete,
  onChange,
  value,
}: IUseAutocompleteParams): [
  string,
  React.KeyboardEventHandler,
  React.FormEventHandler<HTMLInputElement>,
  GestureEventHandler,
] => {
  const [showAutocompleteText, setShowAutocompleteText] = useState(true);

  const autocompleteInputValue = useMemo(() => {
    if (!autocompleteValue || !showAutocompleteText) {
      return '';
    }

    const baseValue = value.toLocaleLowerCase().trimStart();
    if (!baseValue) {
      return '';
    }

    const baseAutocompleteValue = autocompleteValue.toLocaleLowerCase();

    if (
      !baseAutocompleteValue.startsWith(baseValue) ||
      baseAutocompleteValue === baseValue
    ) {
      return '';
    }

    const autocompletePostfix = autocompleteValue.substr(baseValue.length);

    return `${value}${autocompletePostfix}`;
  }, [autocompleteValue, value, showAutocompleteText]);

  const fillAutocompleteValue = useCallback(() => {
    onChange(autocompleteInputValue);
    onAutocomplete?.({
      type: 'autocomplete',
      compId: id,
      previousValue: value,
    });
  }, [id, autocompleteInputValue, onChange, onAutocomplete, value]);

  const onKeyPress: React.KeyboardEventHandler<HTMLInputElement> = useCallback(
    event => {
      if (!autocompleteInputValue) {
        return;
      }

      const { key, currentTarget } = event;
      const { selectionStart, selectionEnd } = currentTarget;

      // Tab or Right Arrow key press (when cursor is at the end of input value) will trigger
      // autocomplete suggestion fill.

      if (key === 'Tab') {
        event.preventDefault();
        event.stopPropagation();
      } else if (
        key !== 'ArrowRight' ||
        selectionStart !== selectionEnd ||
        selectionStart !== value.length
      ) {
        return;
      }

      fillAutocompleteValue();
    },
    [autocompleteInputValue, value, fillAutocompleteValue],
  );

  const onInput: React.FormEventHandler<HTMLInputElement> = event => {
    const { scrollLeft, scrollWidth, clientWidth } = event.currentTarget;

    // We don't have any place where we can show autocomplete text when input has
    // more text than available area (text is overflowing and/or scrolled).
    setShowAutocompleteText(scrollLeft === 0 && scrollWidth <= clientWidth);
  };

  const onSwipeRight: GestureEventHandler = useCallback(() => {
    if (autocompleteInputValue) {
      fillAutocompleteValue();
    }
  }, [autocompleteInputValue, fillAutocompleteValue]);

  return [autocompleteInputValue, onKeyPress, onInput, onSwipeRight];
};
