import React, { useCallback, useEffect, useState } from 'react';

import { SearchSuggestions } from '../../../SearchBox.types';
import { useEventOutside } from '../../../../../providers/useEventOutside/useEventOutside';

export const useSuggestionsVisibility = ({
  isSuggestionsOpen,
  parentRef,
  suggestions,
  suggestionsEnabled,
  useModalSuggestions,
}: {
  isSuggestionsOpen: boolean;
  parentRef: React.RefObject<HTMLDivElement>;
  suggestions: SearchSuggestions;
  suggestionsEnabled: boolean;
  useModalSuggestions: boolean;
}) => {
  const [isSuggestionsVisible, setIsSuggestionsVisible] = useState(true);

  const resetSuggestionsVisibility = useCallback(() => {
    setIsSuggestionsVisible(true);
  }, []);

  useEffect(resetSuggestionsVisibility, [
    suggestionsEnabled,
    isSuggestionsOpen,
    resetSuggestionsVisibility,
  ]);

  const eventOutsideHandler = useCallback(() => {
    setIsSuggestionsVisible(false);
  }, []);

  useEventOutside('focus', [parentRef], eventOutsideHandler);
  useEventOutside('click', [parentRef], eventOutsideHandler);

  return {
    shouldShowSuggestions: Boolean(
      suggestionsEnabled &&
        isSuggestionsOpen &&
        (useModalSuggestions || (isSuggestionsVisible && suggestions)),
    ),
    resetSuggestionsVisibility,
  };
};
